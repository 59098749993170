import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import GuestComp from '../components/GuestComp'
import ChildCareComp from '../components/ChildCareComp'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from "@mui/material/Box";
import successIcon from '../assets/payment-success-icon.png';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';


import StudentInfoComp from '../components/StudentInfoComp'
import ParentInfoComp from '../components/ParentInfoComp'
import SpouseInfoComp from '../components/SpouseInfoComp'
import MedicalInfoComp from '../components/MedicalInfoComp'


var gFeeEnabled = {};

export default function JCHYKRetreatForm() {
    const [eventData, setEventData] = useState({
        parent: {
            memberType: "parent",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 5,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        },
        spouse: {
            memberType: "spouse",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        },
        children: [{
            memberType: "child",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        }],
        guest: [],
        transaction: {
            quantities: 1,
            itemPrice: 0,
            totalAmount: "",
            donationAmount: "",
            paymentStatus: "",
            payerId: ""
        },
        medical: {
            pcpName: "",
            pcpMobile: "",
            medicalInsurance: "",
            groupNo: "",
            idNo: "",
            medicalNotes: "",
            medicalConsentSignedBy: "",
            treatmentConsentSignedBy: ""
        },
        tshirts: [],
        eventType: 'JCHYKRetreat',
        center: '',
        notes: '',
        sessionName: "",
        otherCenter: ''
    });
    const ticketPrice = process.env.REACT_APP_NATIONAL_JCHYK_RETREAT_PRICE;

    console.log("Event data in Payment form :", JSON.stringify(eventData), eventData);
    const [inputFields, setInputFields] = useState(eventData.transaction);
    const [medicalFields, setMedicalFields] = useState(eventData.medical);
    const [studentFields, setStudentFields] = useState(eventData.children);
    const [feeEnabled, setFeeEnabled] = useState(0);
    const [donation, setDonation] = useState(0);
    const [totalAmount, setTotalAmount] = useState(inputFields.quantities * inputFields.itemPrice);

    const [otherCenterFlag, setOtherCenterFlag] = useState(false);
    const [noOfPeople, setNoOfPeople] = useState(1);


    const total = () => {
        inputFields.donationAmount = donation;
        inputFields.totalAmount = totalAmount;
        eventData.transaction = inputFields;
        gFeeEnabled = feeEnabled;
    }
    total();
    //console.log("Donation, totalamounts at the end:", (eventData), inputFields, donation, totalAmount);
    function handleCenterChange(e) {
        setEventData({ ...eventData, [e.target.name]: e.target.value });
        if (e.target.value === 'Other') {
            setOtherCenterFlag(true);
        } else {
            setOtherCenterFlag(false);
        }
    }
    useEffect(() => {
        eventData.transaction.quantities = noOfPeople;
        eventData.transaction.itemPrice = ticketPrice;
        if (feeEnabled) {
            setTotalAmount(((parseInt(noOfPeople) * parseInt(ticketPrice) + parseInt(donation)) * 1.03).toFixed(2));
        } else {
            setTotalAmount((parseInt(noOfPeople) * parseInt(ticketPrice) + parseInt(donation)) * 1.00);
        }

        eventData.transaction.totalAmount = totalAmount;
    }, [noOfPeople, donation, feeEnabled])

    const handleStudentChange = (e, index) => {
        //    console.log("Event data in Student handle form :", (studentFields));        
        let newForm = [...studentFields];
        newForm[index][e.target.name] = e.target.value;
        setStudentFields(newForm);
        eventData.children = newForm;
    }
    const handleAddFields = () => {
        setStudentFields([...studentFields, {
            memberType: "child", firstName: "", lastName: "", grade: "", gender: "", age: 0, email: ""
        }]);
        setNoOfPeople(noOfPeople + 1);
    };

    const handleRemoveFields = (index) => {
        const values = [...studentFields];
        values.splice(index, 1);
        setStudentFields(values);
        eventData.children = values;
        setNoOfPeople(noOfPeople - 1);
    };

    function handleMedicalChange(e) {
        let newForm = { ...medicalFields };
        newForm[e.target.name] = e.target.value;
        setMedicalFields(newForm);
        eventData.medical = newForm;
    }

    const checkoutHandler = (e) => {
        e.preventDefault();
        //    console.log("transaction before sent to SQS:", (inputFields));
        if (sendToServer()) {
            setCheckout(true);
        } else {
            setCheckout(false);
        }
    };

    function handleCampChange(e) {
        setEventData({ ...eventData, [e.target.name]: e.target.value });
    }

    function sendToServer() {
        console.log("transaction before sent to SQS:", (eventData));

        try {
            const url = process.env.REACT_APP_SQS_URL;

            const response = fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(eventData),
            });
            console.log('API response:', response.data);
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
        return true;
    }

    function feeSwitchHandler(e) {
        setFeeEnabled(e.target.checked);
    }

    function handleBack() {
        setCheckout(false);
    }


    function handleDonation(e) {
        setInputFields({ ...inputFields, [e.target.name]: parseInt(e.target.value) });
        if (e.target.value >= 0) {
            setDonation(parseInt(e.target.value));
        }
    };

    const initialOptions = {
        clientId: process.env.REACT_APP_PROD_CLIENT_ID,
        currency: "USD",
        intent: "capture",
        "disable-funding": "paylater",
    };

    const [success, setSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [orderId, setOrderId] = useState(false)
    const [checkout, setCheckout] = useState(false)


    const createOrder = (data, actions) => {
        var itemArray = [];

        if (eventData.transaction.quantities > 0) {
            itemArray[itemArray.length] = {
                name: "National JCHYK Retreat",
                description: "National JCHYK Retreat",
                unit_amount: {
                    currency_code: "USD",
                    value: "" + parseInt(inputFields.itemPrice * inputFields.quantities),
                },
                quantity: "1",
            }
        }
        console.log('items array donation:', eventData.transaction.donationAmount);
        if (eventData.transaction.donationAmount > 0) {
            itemArray[itemArray.length] = {
                name: "Donation Amount",
                description: "Donation item",
                unit_amount: {
                    currency_code: "USD",
                    value: eventData.transaction.donationAmount,
                },
                quantity: "1",
            }
        }
        console.log('items array fee:', gFeeEnabled);
        if (gFeeEnabled > 0) {
            itemArray[itemArray.length] = {
                name: "Credit Card processing Fee Amount",
                description: "Credit Card Fee item",
                unit_amount: {
                    currency_code: "USD",
                    value: ((parseInt(inputFields.quantities) * inputFields.itemPrice + parseInt(eventData.transaction.donationAmount)) * 0.03).toFixed(2),
                },
                quantity: "1",
            }
        }
        console.log('items array in create order 2:', itemArray, eventData.transaction.totalAmount, eventData.transaction.donationAmount);
        return actions.order.create({
            purchase_units: [
                {
                    description: "National JCHYK Retreat",
                    amount: {
                        value: eventData.transaction.totalAmount,
                        currency_code: "USD",
                        breakdown: {
                            item_total: {
                                value: eventData.transaction.totalAmount,
                                currency_code: "USD",
                            }
                        }
                    },

                    items: itemArray, /**/
                },
            ],
            application_context: {
                shipping_preference: "NO_SHIPPING"
            }
        })
            .then((orderId) => {
                setOrderId(orderId)
                return orderId
            })
    }
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details
            setSuccess(true);
            setCheckout(true);
            eventData.transaction.paymentStatus = 'paid';
            eventData.transaction.payerId = payer.payer_id;
            console.log('Payer info : ', payer);
            console.log('on Approve Event data : ', eventData);
            sendToServer();
        })
    }
    const onError = (data, actions) => {
        setErrorMessage("An error occured with your payment")
    }

    return (
        <div>
            <form onSubmit={checkoutHandler}>
                <Grid container direction="column" alignItems="center" >
                    {
                        checkout ?
                            success ?
                                <Grid item>
                                    <img src={successIcon} className="Success-icon" alt="logo" />
                                    <Typography variant="h5" align="center" gutterBottom>
                                        {'Thank You for the Registration'}
                                    </Typography>
                                    <Box sx={{ width: '100%', maxWidth: 500 }}>
                                        <Typography variant="body2" align="left" gutterBottom>
                                            You will receive an email confirmation to this address <b> [ {eventData.parent.email} ] </b>
                                        </Typography>
                                    </Box>
                                </Grid>
                                :
                                <Grid item>

                                    <span>
                                        <Box sx={{ width: '100%', maxWidth: 500 }}>
                                            <Typography variant="h6" align="left" gutterBottom>
                                                <b>If you want to make any changes, please click "Back" button otherwise click "Paypal"
                                          button to complete the transactions</b>
                                                <div><br /></div>
                                            </Typography>
                                        </Box>
                                    </span>
                                    <PayPalScriptProvider options={initialOptions}>
                                        <PayPalButtons style={{ layout: "vertical", label: "pay" }}
                                            createOrder={createOrder}
                                            onApprove={onApprove}

                                        />
                                    </PayPalScriptProvider>
                                    <span>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => handleBack()}>Back</Button>
                                    </span>
                                </Grid>
                            :
                            <div>
                                <Grid item xs={12} sm={12}>
                                    <Grid item>
                                        <Box sx={{ width: '100%', minWidth: 300 }}>
                                            <Typography variant="body2" align="left" gutterBottom>
                                                <b>Where:</b>Chinmaya Mangalam | 10470 FM744, Barry TX, 75102
                                            <div><b>Who Can Attend:</b>High Schoolers (Incoming 9th graders to 2024 graduates)</div>
                                                <div><b>When: </b>Mon, July 1st 4:00 PM - Fri, July 5, 2024 12:00 PM (Overnight 4 Day)</div>
                                                <b>COST: </b>$250/ per Student
                                                <div><br /></div>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }} size="small">
                                        <InputLabel id="demo-simple-select-standard-label">Select a Center</InputLabel>
                                        <Select
                                            id="standard"
                                            required
                                            name="center"
                                            label="Center"
                                            value={eventData.center}
                                            onChange={handleCenterChange}
                                        >
                                            <MenuItem value="Chitrakoot">Chitrakoot</MenuItem>
                                            <MenuItem value="Frisco">Frisco</MenuItem>
                                            <MenuItem value="Saaket">Saaket</MenuItem>
                                            <MenuItem value="Other">Other</MenuItem>

                                        </Select>
                                        {otherCenterFlag && (
                                            <TextField
                                                fullWidth
                                                id="otherCenter"
                                                label="Enter Center :"
                                                sx={{ m: 1, minWidth: 150 }}
                                                variant="standard"
                                                name="otherCenter"
                                                value={eventData.otherCenter}
                                                onChange={e => setEventData({ ...eventData, [e.target.name]: e.target.value })}
                                            />)}
                                    </FormControl>
                                    <div><br /></div>
                                </Grid>

                                <Grid item>
                                    <Grid container alignItems="center" >
                                        <Typography variant="h7" align="left" gutterBottom>
                                            <b>Bala Vihar Student Information</b>
                                        </Typography>
                                        <Grid item xs={12} sm={12}>
                                            <Grid container align="left" >
                                                {studentFields.map((studentFields, index) => (
                                                    <div key={index} align="left">
                                                        <Grid container spacing={2} >
                                                            <Grid item xs={12} sm={6}>
                                                                <TextField
                                                                    required
                                                                    fullWidth
                                                                    id="fName"
                                                                    label="First Name"
                                                                    name="firstName"
                                                                    variant="standard"
                                                                    value={studentFields.firstName}
                                                                    onChange={(e) => handleStudentChange(e, index)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <TextField
                                                                    required
                                                                    fullWidth
                                                                    id="lName"
                                                                    label="Last Name"
                                                                    name="lastName"
                                                                    variant="standard"
                                                                    value={studentFields.lastName}
                                                                    onChange={(e) => handleStudentChange(e, index)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6} sm={6}>
                                                                <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }} size="small">
                                                                    <InputLabel id="demo-simple-select-standard-label">Grade</InputLabel>
                                                                    <Select
                                                                        id="grade"
                                                                        name="grade"
                                                                        value={studentFields.grade}
                                                                        onChange={(e) => handleStudentChange(e, index)}
                                                                        label="Grade"
                                                                    >
                                                                        <MenuItem value="Grade-9">Grade-9</MenuItem>
                                                                        <MenuItem value="Grade-10">Grade-10</MenuItem>
                                                                        <MenuItem value="Grade-11">Grade-11</MenuItem>
                                                                        <MenuItem value="Grade-12">Grade-12</MenuItem>
                                                                        <MenuItem value="HS-Graduate-of-2024">HS Graduate of 2024</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6}>
                                                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
                                                                    <InputLabel id="demo-simple-select-standard-label">Gender</InputLabel>
                                                                    <Select
                                                                        required
                                                                        id="standard"
                                                                        name="gender"

                                                                        value={studentFields.gender}
                                                                        onChange={(e) => handleStudentChange(e, index)}
                                                                        label="Gender"
                                                                    >
                                                                        <MenuItem value="Male">Male</MenuItem>
                                                                        <MenuItem value="Female">Female</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6}>
                                                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
                                                                    <InputLabel id="demo-simple-select-standard-label">T-Shirt Size</InputLabel>
                                                                    <Select
                                                                        id="standard"
                                                                        name="tshirtSize"
                                                                        value={studentFields.tshirtSize}
                                                                        onChange={(e) => handleStudentChange(e, index)}
                                                                        label="Tshirt Size"
                                                                    >
                                                                        <MenuItem value="Adult-XL">Adult-XL</MenuItem>
                                                                        <MenuItem value="Adult-L">Adult-L</MenuItem>
                                                                        <MenuItem value="Adult-M">Adult-M</MenuItem>
                                                                        <MenuItem value="Adult-S">Adult-S</MenuItem>

                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <TextField
                                                                    required
                                                                    fullWidth
                                                                    id="mobile"
                                                                    label="Mobile Phone"
                                                                    name="mobileNumber"
                                                                    variant="standard"
                                                                    value={studentFields.mobileNumber}
                                                                    onChange={(e) => handleStudentChange(e, index)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <TextField
                                                                    type="email"
                                                                    fullWidth
                                                                    required id="email" label="Email" variant="standard"
                                                                    name="email"
                                                                    value={studentFields.email}
                                                                    onChange={(e) => handleStudentChange(e, index)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12}>
                                                                <TextField
                                                                    required
                                                                    fullWidth
                                                                    multiline
                                                                    id="allergies"
                                                                    label="Notes(food , medicines, or environmental allergies)"
                                                                    name="allergies"
                                                                    variant="standard"
                                                                    value={studentFields.allergies}
                                                                    onChange={(e) => handleStudentChange(e, index)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12}>
                                                                <div><br /></div>
                                                                <b>Add/Delete Additional students</b>
                                                                {index >= 1 ?
                                                                    <IconButton onClick={() => handleRemoveFields(index)}>
                                                                        <DeleteIcon />
                                                                    </IconButton> : ''
                                                                }
                                                                <IconButton onClick={() => handleAddFields(index)}>
                                                                    <PersonAddIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                ))}

                                            </Grid>
                                        </Grid>
                                    </Grid>


                                </Grid>
                                <div><br /></div>
                                <ParentInfoComp eventData={eventData} />
                                <SpouseInfoComp eventData={eventData} setEventData={setEventData} />
                                <div><br /></div>
                                <Grid item xs={12} sm={12}>
                                    <Box sx={{ width: '100%' }}>
                                        <Typography variant="body2" align="left" gutterBottom>
                                            * We will contact you primarily through email with camp information, and in case of emergencies
                                by phone. Please ensure that you regularly check or respond to the email account/ phone
                                number you have submitted above.
                                </Typography>
                                    </Box>
                                </Grid>
                                <Grid container direction="column" align="left">
                                    <div><br /></div>
                                    <Typography variant="h6" align="left" gutterBottom>
                                        <b>Medical Information</b>
                                    </Typography>

                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="pcpName"
                                                label="Primay Care Physican"
                                                name="pcpName"
                                                variant="standard"
                                                value={medicalFields.pcpName}
                                                onChange={handleMedicalChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="pcpmobile"
                                                label="Physican Phone #"
                                                name="pcpMobile"
                                                variant="standard"
                                                value={medicalFields.pcpMobile}
                                                onChange={handleMedicalChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                fullWidth
                                                id="medicalInsurance"
                                                label="Medical Insurance"
                                                name="medicalInsurance"
                                                variant="standard"
                                                value={medicalFields.medicalInsurance}
                                                onChange={handleMedicalChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                id="groupNo"
                                                label="Group No"
                                                name="groupNo"
                                                variant="standard"
                                                value={medicalFields.groupNo}
                                                onChange={handleMedicalChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                id="idNo"
                                                label="ID No (if any)"
                                                name="idNo"
                                                variant="standard"
                                                value={medicalFields.idNo}
                                                onChange={handleMedicalChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h8" align="left" gutterBottom>
                                                <b>Release and Medical Authorization</b>
                                            </Typography>
                                            <div><br /></div>
                                            RELEASE OF LIABILITY - In consideration of Chinmaya Mission granting the above named
                                retreater permission to participate in the labor day retreat activities including being transported to
                                and back from the retreat location, I hereby assume all risks of her/his personal injury (including
                                death) that may result from any retreat activity (including residence hall activities). As
                                parent/guardian/responsible party, I do indemnify, defend and hold harmless, Chinmaya Mission
                                and its officers, Volunteer parents and all participants in the retreat program from and against all
                                liability, including claims and suits at law or in equity, for injury, fatal or otherwise, which may
                                result from any negligence and/or the retreater taking part in activities.
                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                id="medicalConsentSignedBy"
                                                required
                                                fullWidth
                                                label="Signature  (Type your Name) :"
                                                name="medicalConsentSignedBy"
                                                variant="standard"
                                                value={medicalFields.medicalConsentSignedBy}
                                                onChange={handleMedicalChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h8" align="left" gutterBottom>
                                                <b>CONSENT FOR TREATMENT AND/OR FIRST AID</b>
                                            </Typography>
                                            <div><br /></div>
                                            <p>
                                                In the event of injury or illness, I hereby
                                give my consent for medical treatment and permission to the assigned retreat doctor or any
                                adult retreat volunteer or any Hospital to supervise on-site first aid for minor injuries, and to a
                                licensed physician to hospitalize and secure proper treatment (including injections, anesthesia,
                                surgery, or other reasonable treatment and necessary procedures) for the retreater.
                                </p>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                id="treatmentConsentSignedBy"
                                                required
                                                fullWidth
                                                label="Signature (Type your Name) :"
                                                name="treatmentConsentSignedBy"
                                                variant="standard"
                                                value={medicalFields.treatmentConsentSignedBy}
                                                onChange={handleMedicalChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <div><br /></div>
                                <div><br /></div>
                                <Typography variant="h6" align="left" gutterBottom>
                                    <b>Payment Information</b>
                                </Typography>
                                <div><br /></div>
                                <Grid container direction="row" spacing={2} >
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="Quantity"
                                            fullWidth
                                            disabled
                                            label="Quantities #"
                                            type="number"
                                            name="Quantities"
                                            variant="standard"
                                            value={inputFields.quantities}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            number
                                            id="donation"
                                            label="Additional Donation ($)"
                                            type="number"
                                            name="donation"
                                            variant="standard"
                                            value={donation}
                                            onChange={handleDonation}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={feeEnabled} />} label="Add 3% to cover for CC fees"
                                                value={feeEnabled} size="small" onChange={feeSwitchHandler} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            disabled
                                            id="totalAmountField"
                                            label="Total Amount($)"
                                            type="number"
                                            name="totalAmount"
                                            variant="standard"
                                            inputProps={{ style: { fontSize: 20 } }} // font size of input text
                                            InputLabelProps={{ style: { fontSize: 20 } }} // font size of input label
                                            value={totalAmount}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container direction="column" spacing={5}>
                                    <Grid item>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Checkout</Button>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" align="center" gutterBottom>
                                            If you have any questions, please contact us at <a href="mailto:events@cmdfw.org">events@cmdfw.org</a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                    }
                </Grid>
            </form>
        </div >
    );
}