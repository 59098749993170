import React, { useState, useEffect } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import StudentInfoComp from '../components/StudentInfoComp'
import ParentInfoComp from '../components/ParentInfoComp'

const DemoPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    ...theme.typography.body2,
    textAlign: 'center',
}));

export default function CampFormComp(eventData, setEventData) {

    console.log("Event data in Camp form :", JSON.stringify(eventData));
    //const [eventData, setEventData] = useState(eventData);
    //<PrimaryMemberComp eventData={eventData} />
    //<ParentInfoComp eventData={eventData} setEventData={setEventData} />
    //<ParentInfoComp eventData={eventData.members} setEventData={setEventData} />
    return (
        <div>
            <CssBaseline />

            <Container maxWidth="xs">
                <DemoPaper variant="elevation">
                    <Grid item>
                        <ParentInfoComp eventData={eventData} setEventData={setEventData} />


                    </Grid>
                </DemoPaper>
            </Container>
        </div>
    );
}