import React, { useState, useEffect } from 'react';
import logo from '../assets/banquet-banner.png';
import swami from '../assets/images/cards/Parenting_CMDFW_7455.jpg'
import { Grid, Typography, Paper } from '@mui/material';
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Tile from './Tile';


export default function BannerComp({ eventData, setEventData }) {
    console.log("Event data in Banner 1 :", JSON.stringify(eventData));

    function handleChange(e) {
        setEventData({ ...eventData, [e.target.name]: e.target.value });
    }
    function handleRegisterClick(e) {
        setEventData({ ...eventData, [e.target.name]: e.target.value });
    }

    return (
        <div>
            <Grid container alignItems="center">
                <Grid item xs={12} sm={12}>
                    <Typography variant="h6" align="center" gutterBottom>
                        {'CMDFW Event Registration System'}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <img src={logo} className="App-logo" alt="logo" />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 270 }} size="small">
                        <InputLabel id="demo-simple-select-standard-label">Select an Event</InputLabel>
                        <Select
                            id="standard"
                            name="eventType"
                            value={eventData.eventType}
                            onChange={handleChange}
                            label="eventType"
                        >
                            <MenuItem value="Donation">Donation</MenuItem>
                            <MenuItem value="LaborDayRetreat">Labor Day Retreat</MenuItem>                            
                        </Select>
                    </FormControl>
                </Grid>
                          
            </Grid>
        </div>
    );

}
