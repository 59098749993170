import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { Grid, Typography, Paper } from '@mui/material';
import Divider from "@mui/material/Divider";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import GuestComp from '../components/GuestComp'
import ChildCareComp from '../components/ChildCareComp'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from "@mui/material/Box";
import successIcon from '../assets/payment-success-icon.png';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import StudentInfoComp from '../components/StudentInfoComp'
import ParentInfoComp from '../components/ParentInfoComp'
import SpouseInfoComp from '../components/SpouseInfoComp'
import RetreatRulesComp from '../components/RetreatRulesComp'
import MedicalInfoComp from '../components/MedicalInfoComp'


var gFeeEnabled = {};

export default function ParentingWorkshopForm() {
    const [eventData, setEventData] = useState({
        parent: {
            memberType: "parent",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 5,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        },
        spouse: {
            memberType: "spouse",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        },
        children: [{
            memberType: "child",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            foodAllergies: "",
            medicalAllergies: "",
            environmentalAllergies: "",
            prescriptionMedications: "",
            otcMedications: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        }],
        guest: [],
        transaction: {
            quantities: 1,
            itemPrice: 0,
            totalAmount: "",
            donationAmount: "",
            paymentStatus: "paid",
            payerId: "Dummy"
        },
        medical: {
            pcpName: "",
            pcpMobile: "",
            medicalInsurance: "",
            groupNo: "",
            idNo: "",
            medicalNotes: "",
            medicalConsentSignedBy: "",
            treatmentConsentSignedBy: ""
        },
        tshirts: [],
        eventType: 'ParentingWorkshop',
        center: '',
        notes: '',
        sessionName: "",
        otherCenter: ''
    });
    const ticketPrice = process.env.REACT_APP_LABORDAY_RETREAT_PRICE;

    console.log("Event data in Payment form :", JSON.stringify(eventData), eventData);
    const [inputFields, setInputFields] = useState(eventData.transaction);
    const [medicalFields, setMedicalFields] = useState(eventData.medical);
    const [studentFields, setStudentFields] = useState(eventData.children);
    const [parentFields, setParentFields] = useState(eventData.parent);
    const [spouseFields, setSpouseFields] = useState(eventData.spouse);

    const [feeEnabled, setFeeEnabled] = useState(0);
    const [donation, setDonation] = useState(0);
    const [totalAmount, setTotalAmount] = useState(inputFields.quantities * inputFields.itemPrice);

    const [otherCenterFlag, setOtherCenterFlag] = useState(false);
    const [noOfPeople, setNoOfPeople] = useState(1);


    const total = () => {
        inputFields.donationAmount = donation;
        inputFields.totalAmount = totalAmount;
        eventData.transaction = inputFields;
        gFeeEnabled = feeEnabled;
    }
    total();
    //console.log("Donation, totalamounts at the end:", (eventData), inputFields, donation, totalAmount);
    function handleCenterChange(e) {
        setEventData({ ...eventData, [e.target.name]: e.target.value });
        if (e.target.value === 'Other') {
            setOtherCenterFlag(true);
        } else {
            setOtherCenterFlag(false);
        }
    }
    useEffect(() => {
        eventData.transaction.quantities = noOfPeople;
        eventData.transaction.itemPrice = ticketPrice;
        if (feeEnabled) {
            setTotalAmount(((parseInt(noOfPeople) * parseInt(ticketPrice) + parseInt(donation)) * 1.03).toFixed(2));
        } else {
            setTotalAmount((parseInt(noOfPeople) * parseInt(ticketPrice) + parseInt(donation)) * 1.00);
        }

        eventData.transaction.totalAmount = totalAmount;
    }, [noOfPeople, donation, feeEnabled])

    const handleStudentChange = (e, index) => {
        //    console.log("Event data in Student handle form :", (studentFields));        
        let newForm = [...studentFields];
        newForm[index][e.target.name] = e.target.value;
        setStudentFields(newForm);
        eventData.children = newForm;        
    }
    const handleAddFields = () => {
        setStudentFields([...studentFields, {
            memberType: "child", firstName: "", lastName: "", grade: "", gender: "", age: 0, email: ""
        }]);
        setNoOfPeople(noOfPeople + 1);
    };

    const handleRemoveFields = (index) => {
        const values = [...studentFields];
        values.splice(index, 1);
        setStudentFields(values);
        eventData.children = values;
        setNoOfPeople(noOfPeople - 1);
    };

    function handleMedicalChange(e) {
        let newForm = { ...medicalFields };
        newForm[e.target.name] = e.target.value;
        setMedicalFields(newForm);
        eventData.medical = newForm;
    }

    const checkoutHandler = (e) => {
        e.preventDefault();
        //    console.log("transaction before sent to SQS:", (inputFields));
        if (sendToServer()) {
            setCheckout(true);
            setSuccess(true);
        } else {
            setCheckout(false);
        }
    };

    // Handle 5 responses from student allergy information 
    //const [foodAllergies, setFoodAllergies] = useState('');
    //const [medicalAllergies, setMedicaldAllergies] = useState('');
  
    //const handleFoodAllergiesChange = (e) => {
    //const newFirstName = e.target.value;
    //setFirstName(newFirstName);
    //setFullName(newFirstName + ' ' + lastName);
  //};

    function handleParentChange(e) {
        let newForm = { ...parentFields };
        newForm[e.target.name] = e.target.value;
        setParentFields(newForm);
        eventData.parent = newForm;
    }
    function handleSpouseChange(e) {
        let newForm = { ...spouseFields };
        newForm[e.target.name] = e.target.value;
        setSpouseFields(newForm);
        eventData.spouse = newForm;
    }
    function handleChange(e) {
        //Handle any changes to the text fields
        setEventData({ ...eventData, [e.target.name]: e.target.value });
    }

    function handleCampChange(e) {
        setEventData({ ...eventData, [e.target.name]: e.target.value });
    }

    function sendToServer() {
        console.log("transaction before sent to SQS:", (eventData));

        try {
            const url = process.env.REACT_APP_SQS_URL;

            const response = fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(eventData),
            });
            console.log('API response:', response.data);
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
        return true;
    }

    function feeSwitchHandler(e) {
        setFeeEnabled(e.target.checked);
    }

    function handleBack() {
        setCheckout(false);
    }


    function handleDonation(e) {
        setInputFields({ ...inputFields, [e.target.name]: parseInt(e.target.value) });
        if (e.target.value >= 0) {
            setDonation(parseInt(e.target.value));
        }
    };

    const initialOptions = {
        clientId: process.env.REACT_APP_PROD_CLIENT_ID,
        currency: "USD",
        intent: "capture",
        "disable-funding": "paylater",
    };

    const [success, setSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [orderId, setOrderId] = useState(false)
    const [checkout, setCheckout] = useState(false)


    const createOrder = (data, actions) => {
        var itemArray = [];

        if (eventData.transaction.quantities > 0) {
            itemArray[itemArray.length] = {
                name: "Labor Day Retreat",
                description: "Labor Day Retreat",
                unit_amount: {
                    currency_code: "USD",
                    value: "" + parseInt(inputFields.itemPrice * inputFields.quantities),
                },
                quantity: "1",
            }
        }
        console.log('items array donation:', eventData.transaction.donationAmount);
        if (eventData.transaction.donationAmount > 0) {
            itemArray[itemArray.length] = {
                name: "Donation Amount",
                description: "Donation item",
                unit_amount: {
                    currency_code: "USD",
                    value: eventData.transaction.donationAmount,
                },
                quantity: "1",
            }
        }
        console.log('items array fee:', gFeeEnabled);
        if (gFeeEnabled > 0) {
            itemArray[itemArray.length] = {
                name: "Credit Card processing Fee Amount",
                description: "Credit Card Fee item",
                unit_amount: {
                    currency_code: "USD",
                    value: ((parseInt(inputFields.quantities) * inputFields.itemPrice + parseInt(eventData.transaction.donationAmount)) * 0.03).toFixed(2),
                },
                quantity: "1",
            }
        }
        console.log('items array in create order 2:', itemArray, eventData.transaction.totalAmount, eventData.transaction.donationAmount);
        return actions.order.create({
            purchase_units: [
                {
                    description: "Labor Day Retreat",
                    amount: {
                        value: eventData.transaction.totalAmount,
                        currency_code: "USD",
                        breakdown: {
                            item_total: {
                                value: eventData.transaction.totalAmount,
                                currency_code: "USD",
                            }
                        }
                    },

                    items: itemArray, /**/
                },
            ],
            application_context: {
                shipping_preference: "NO_SHIPPING"
            }
        })
            .then((orderId) => {
                setOrderId(orderId)
                return orderId
            })
    }
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details
            setSuccess(true);
            setCheckout(true);
            eventData.transaction.paymentStatus = 'paid';
            eventData.transaction.payerId = payer.payer_id;
            console.log('Payer info : ', payer);
            console.log('on Approve Event data : ', eventData);
            sendToServer();
        })
    }
    const onError = (data, actions) => {
        setErrorMessage("An error occured with your payment")
    }

    return (
        <div>
            <form onSubmit={checkoutHandler}>
                <Grid container direction="column" alignItems="center" >
                    {
                        checkout ?
                            success ?
                                <Grid item>
                                    <img src={successIcon} className="Success-icon" alt="logo" />
                                    <Typography variant="h5" align="center" gutterBottom>
                                        {'Thank You for the Registration'}
                                    </Typography>
                                    <Box sx={{ width: '100%', maxWidth: 500 }}>
                                        <Typography variant="body2" align="left" gutterBottom>
                                            You will receive an email confirmation to this address <b> [ {eventData.parent.email} ] </b>
                                        </Typography>
                                    </Box>
                                </Grid>
                                :
                                <Grid item>

                                    <span>
                                        <Box sx={{ width: '100%', maxWidth: 500 }}>
                                            <Typography variant="h6" align="left" gutterBottom>
                                                <b>If you want to make any changes, please click "Back" button otherwise click "Paypal"
                                          button to complete the transactions</b>
                                                <div><br /></div>
                                            </Typography>
                                        </Box>
                                    </span>
                                    <PayPalScriptProvider options={initialOptions}>
                                        <PayPalButtons style={{ layout: "vertical", label: "pay" }}
                                            createOrder={createOrder}
                                            onApprove={onApprove}

                                        />
                                    </PayPalScriptProvider>
                                    <span>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => handleBack()}>Back</Button>
                                    </span>
                                </Grid>
                            :
                            <div>
                                <Grid item xs={12} sm={12}>
                                    <Grid item>
                                        <Box sx={{ width: '100%', minWidth: 300 }}>
                                            <Typography variant="body2" align="left" gutterBottom>
                                                <b>Where: </b>Chinmaya Saaket | 17701 Davenport Rd, Dallas TX, 75252
                                            <div><b>Who Can Attend: Adults Only</b></div>
                                                <div><b>When: </b>Sat, August 24th - 9:00 AM to Noon</div>
                                                <b>COST: </b>Free Event - <b>Registration required (Lunch will be provided)</b>
                                                <div><br /></div>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }} size="small">
                                        <InputLabel id="demo-simple-select-standard-label">Select a Center</InputLabel>
                                        <Select
                                            id="standard"
                                            required
                                            name="center"
                                            label="Center"
                                            value={eventData.center}
                                            onChange={handleCenterChange}
                                        >
                                            <MenuItem value="Chitrakoot">Chitrakoot</MenuItem>
                                            <MenuItem value="Frisco">Frisco</MenuItem>
                                            <MenuItem value="Saaket">Saaket</MenuItem>
                                            <MenuItem value="Other">Other</MenuItem>

                                        </Select>
                                        {otherCenterFlag && (
                                            <TextField
                                                fullWidth
                                                id="otherCenter"
                                                label="Enter Location :"
                                                sx={{ m: 1, minWidth: 150 }}
                                                variant="standard"
                                                name="otherCenter"
                                                value={eventData.otherCenter}
                                                onChange={e => setEventData({ ...eventData, [e.target.name]: e.target.value })}
                                            />)}
                                    </FormControl>
                                    <div><br /></div>
                                </Grid>

                                <Grid item>
                                    <Grid container alignItems="left" >
                                    <Paper elevation={3}>
                                        <Paper style={{ padding: '16px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>
                                        <Typography variant="h7" align="left" gutterBottom>
                                            <b>Parent Information</b>
                                        </Typography>
                                        </Paper>
                                        <div><br /></div>
                                        <Grid item xs={12} sm={12}>
                                        <Grid container align="left" spacing={2}>                                       
                                            <Grid item xs={12} sm={6}>
                                            <Paper elevation={3}>
                                                <TextField
                                                    required
                                                    id="fName"
                                                    label="First Name"
                                                    name="firstName"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={parentFields.firstName}
                                                    onChange={handleParentChange}
                                                />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                            <Paper elevation={3}>
                                                <TextField
                                                    required
                                                    id="lName"
                                                    label="Last Name"
                                                    name="lastName"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={parentFields.lastName}
                                                    onChange={handleParentChange}
                                                />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                            <Paper elevation={3}>
                                                <TextField
                                                    required
                                                    id="mobile"
                                                    label="Mobile Phone"
                                                    name="mobileNumber"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={parentFields.mobileNumber}
                                                    onChange={handleParentChange}
                                                />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                            <Paper elevation={3}>
                                                <TextField
                                                    type="email"
                                                    fullWidth
                                                    required
                                                    id="email"
                                                    label="Email"
                                                    variant="outlined"
                                                    name="email"
                                                    value={parentFields.email}
                                                    onChange={handleParentChange}
                                                />
                                            </Paper>
                                            </Grid>
                                        </Grid>
                                        </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <div><br /></div>
                                <Grid item>
                                    <Grid container alignItems="left" >
                                    <Paper elevation={3}>
                                        <Paper style={{ padding: '16px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>
                                        <Typography variant="h7" align="left" gutterBottom>
                                            <b>Spouse Information</b>
                                        </Typography>
                                        </Paper>
                                        <div><br /></div>
                                        <Grid item xs={12} sm={12}>
                                        <Grid container align="left" spacing={2}>                                       
                                            <Grid item xs={12} sm={6}>
                                            <Paper elevation={3}>
                                                <TextField
                                                    
                                                    id="fName"
                                                    label="First Name"
                                                    name="firstName"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={spouseFields.firstName}
                                                    onChange={handleSpouseChange}
                                                />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                            <Paper elevation={3}>
                                                <TextField
                                                    
                                                    id="lName"
                                                    label="Last Name"
                                                    name="lastName"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={spouseFields.lastName}
                                                    onChange={handleSpouseChange}
                                                />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                            <Paper elevation={3}>
                                                <TextField
                                                    id="mobile"
                                                    label="Mobile Phone"
                                                    name="mobileNumber"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={spouseFields.mobileNumber}
                                                    onChange={handleSpouseChange}
                                                />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                            <Paper elevation={3}>
                                                <TextField
                                                    type="email"
                                                    fullWidth
                                                    
                                                    id="email"
                                                    label="Email"
                                                    variant="outlined"
                                                    name="email"
                                                    value={spouseFields.email}
                                                    onChange={handleSpouseChange}
                                                />
                                            </Paper>
                                            </Grid>
                                        </Grid>
                                        </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                
                                <div><br /></div>
                                <Grid container direction="column" >
                                <Paper elevation={3}>
                                    <Grid item>
                                        <TextField
                                            id="notes"
                                            rows="5"
                                            required
                                            fullWidth
                                            multiline
                                            label="Notes (# of Children and Grades):"
                                            name="notes"
                                            variant="outlined"
                                            value={eventData.notes}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    </Paper>
                                </Grid>
                                <Grid container direction="column" spacing={5}>
                                    <Grid item>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Register</Button>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" align="center" gutterBottom>
                                            If you have any questions, please contact us at <a href="mailto:events@cmdfw.org">events@cmdfw.org</a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                    }
                </Grid>
            </form>
        </div >
    );
}