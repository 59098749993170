import React, { useState, useEffect } from 'react';
import logo from '../assets/banquet-banner.png';
import { Grid, Typography, Paper } from '@mui/material';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';


export default function SpouseInfoComp({ eventData, setEventData }) {
    console.log("Event data in Spouse form 1 :", JSON.stringify(eventData));
    const [inputFields, setInputFields] = useState(eventData.spouse);
    /*
    const handleChange = (e) => {
        let newForm = { ...inputFields };
        newForm[e.target.name] = e.target.value;
        setInputFields(newForm);
        eventData.spouse = newForm;
        console.log("Event data in Parent Info change form 2 :", (eventData), (inputFields));
    } */

    function handleChange(e) {
        // setInputFields({ ...inputFields, [e.target.name]: e.target.value });
        // eventData.spouse = inputFields;
        let newForm = { ...inputFields };
        newForm[e.target.name] = e.target.value;
        setInputFields(newForm);
        eventData.spouse = newForm;
        console.log("Event data in Parent Info change form 2 :", (eventData), (inputFields));
    }

    return (
        <div>
            <React.Fragment>
                <Grid container direction="column" align="left">
                    <div><br /></div>
                    <Typography variant="h8" align="left" gutterBottom>
                        <b>Spouse/Guardian 2/ Responsible Party</b>
                    </Typography>
                    <div align="left"><br /></div>
                    <Grid container  spacing={2}>
                        <Grid item  xs={12} sm={6}>
                        <Paper elevation={3}>
                            <TextField
                                fullWidth
                                required
                                id="fName"
                                label="First Name"
                                name="firstName"
                                variant="outlined"
                                value={inputFields.firstName}
                                onChange={handleChange}
                            />
                            </Paper>
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                        <Paper elevation={3}>
                            <TextField
                                fullWidth
                                required
                                id="lName"
                                label="Last Name"
                                name="lastName"
                                variant="outlined"
                                value={inputFields.lastName}
                                onChange={handleChange}
                            />
                            </Paper>
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                        <Paper elevation={3}>
                            <TextField
                                fullWidth
                                required
                                id="mobile"
                                label="Mobile Phone"
                                name="mobileNumber"
                                variant="outlined"
                                value={inputFields.mobileNumber}
                                onChange={handleChange}
                            />
                            </Paper>
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                        <Paper elevation={3}>
                            <TextField
                                type="email"
                                fullWidth
                                required id="email" label="Email" variant="outlined"
                                name="email"
                                value={inputFields.email}
                                onChange={handleChange}
                            />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        </div>
    );
}
