import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import BannerComp from './components/BannerComp'
import PrimaryMemberComp from './components/PrimaryMemberComp'
import GuestComp from './components/GuestComp'
import ChildCareComp from './components/ChildCareComp'
import PaymentComp from './components/PaymentComp'

const DemoPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    ...theme.typography.body2,
    textAlign: 'center',
}));

export default function BanquetForm() {

    return (
        <div>
            <CssBaseline />

            <Container maxWidth="xs">
                <DemoPaper variant="elevation">
                    <BannerComp />
                    <PrimaryMemberComp />
                </DemoPaper>
            </Container>

        </div>
    );
}