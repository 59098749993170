import React, { useState, useEffect } from 'react';
import logo from '../assets/banquet-banner.png';
import { Grid, Typography, Paper } from '@mui/material';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';


export default function ParentInfoComp({ eventData }) {
    // console.log("Event data in Parent form 1 :", (eventData));
    const [addVolunteer, setAddVolunteer] = useState();
    const [inputFields, setInputFields] = useState(eventData.parent);

    function handleChange(e) {
        let newForm = { ...inputFields };
        newForm[e.target.name] = e.target.value;
        setInputFields(newForm);
        eventData.parent = newForm;
        //    console.log("Event data in Parent Info change form 2 :", (eventData), (inputFields));
    }


    return (
        <div>
            <React.Fragment>
                <div align="left"><br />
                
                    <Paper style={{ padding: '16px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>
                        <Typography variant="h7" align="left" gutterBottom style={{ fontWeight: 'bold' }}>                                                                    
                            <b>Parent/Guardian 1/ Responsible Party</b>
                        </Typography>                                            
                    </Paper>                    
                </div>
                <div align="left"><br /></div>
                <Grid container align="left" spacing={2}>
                    <Grid item xs={12} sm={6}>
                    <Paper elevation={3}>
                        <TextField
                            required
                            id="fName"
                            label="First Name"
                            name="firstName"
                            variant="outlined"
                            fullWidth
                            value={inputFields.firstName}
                            onChange={handleChange}
                        />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Paper elevation={3}>
                        <TextField
                            required
                            id="lName"
                            label="Last Name"
                            name="lastName"
                            variant="outlined"
                            fullWidth
                            value={inputFields.lastName}
                            onChange={handleChange}
                        />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Paper elevation={3}>
                        <TextField
                            required
                            id="mobile"
                            label="Mobile Phone"
                            name="mobileNumber"
                            variant="outlined"
                            fullWidth
                            value={inputFields.mobileNumber}
                            onChange={handleChange}
                        />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Paper elevation={3}>
                        <TextField
                            type="email"
                            fullWidth
                            required
                            id="email"
                            label="Email"
                            variant="outlined"
                            name="email"
                            value={inputFields.email}
                            onChange={handleChange}
                        />
                        </Paper>
                    </Grid>

                </Grid>
            </React.Fragment>
        </div >
    );

}
