import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import GuestComp from './GuestComp'
import ChildCareComp from './ChildCareComp'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from "@mui/material/Box";
import successIcon from '../assets/payment-success-icon.png';


import StudentInfoComp from './StudentInfoComp'
import ParentInfoComp from './ParentInfoComp'
import SpouseInfoComp from './SpouseInfoComp'
import MedicalInfoComp from './MedicalInfoComp'


var gFeeEnabled = {};

export default function PaymentComp() {
    const [eventData, setEventData] = useState({
        parent: {
            memberType: "parent",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 5,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        },
        spouse: {
            memberType: "spouse",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        },
        children: [{
            memberType: "child",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        }],
        guest: [{
            memberType: "Guest",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        }],
        transaction: {
            quantities: 1,
            itemPrice: 0,
            totalAmount: "",
            donationAmount: "",
            paymentStatus: "",
            payerId: ""
        },
        medical: {
            pcpName: "",
            pcpMobile: "",
            medicalInsurance: "",
            groupNo: "",
            idNo: "",
            medicalNotes: "",
            medicalConsentSignedBy: "",
            treatmentConsentSignedBy: ""
        },
        tshirts: [{
            shirtSize: "",
            shirtQuantity: "0"
        }],
        eventType: 'HighSchoolCamp',
        center: '',
        notes: '',
        sessionName: ""
    });
    eventData.transaction.itemPrice = process.env.REACT_APP_HIGHSCHOOL_LOCKIN_PRICE;
    console.log("Event data in Payment form :", JSON.stringify(eventData), eventData);
    const [inputFields, setInputFields] = useState(eventData.transaction);
    const [feeEnabled, setFeeEnabled] = useState(0);
    const [donation, setDonation] = useState(0);
    const [totalAmount, setTotalAmount] = useState(inputFields.quantities * inputFields.itemPrice);
    //console.log("Donation, totalamounts at the begin:", (eventData), inputFields, donation, totalAmount);

    const total = () => {
        inputFields.donationAmount = donation;
        inputFields.totalAmount = totalAmount;
        eventData.transaction = inputFields;
        gFeeEnabled = feeEnabled;
    }
    total();
    //console.log("Donation, totalamounts at the end:", (eventData), inputFields, donation, totalAmount);

    const checkoutHandler = (e) => {
        e.preventDefault();
        //    console.log("transaction before sent to SQS:", (inputFields));
        if (sendToServer()) {
            setCheckout(true);
        } else {
            setCheckout(false);
        }
    };

    function handleCampChange(e) {
        setEventData({ ...eventData, [e.target.name]: e.target.value });
    }

    function sendToServer() {
        if (gFeeEnabled) {
            setTotalAmount(((parseInt(inputFields.quantities) * inputFields.itemPrice + parseInt(donation)) * 1.03).toFixed(2));
        } else {
            setTotalAmount((parseInt(inputFields.quantities) * inputFields.itemPrice + parseInt(donation)) * 1.00);
        }
        //        setTotalAmount((parseInt(inputFields.quantities) * inputFields.itemPrice + parseInt(donation)) * 1.00);
        inputFields.donationAmount = donation;
        inputFields.totalAmount = totalAmount;
        eventData.transaction = inputFields;
        console.log("transaction before sent to SQS:", (eventData));

        try {
            const url = process.env.REACT_APP_SQS_URL;

            const response = fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(eventData),
            });
            console.log('API response:', response.data);
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
        return true;
    }

    function feeSwitchHandler(e) {
        //Calculate total amount based on Credit card fee enabled check box. Add extra 3% to the total amount
        console.log('feeSwitchHandler : ' + e.target.checked);
        setFeeEnabled(e.target.checked);
        if (e.target.checked) {
            setTotalAmount(((parseInt(inputFields.quantities) * inputFields.itemPrice + parseInt(donation)) * 1.03).toFixed(2));
        } else {
            setTotalAmount((parseInt(inputFields.quantities) * inputFields.itemPrice + parseInt(donation)) * 1.00);
        }
    }

    function handleBack() {
        // User clicks on GO BACK button 
        console.log('Go back response:', eventData);
        setCheckout(false);
        //setPrimMem(gPrimMem);
    }


    function handleDonation(e) {
        setInputFields({ ...inputFields, [e.target.name]: parseInt(e.target.value) }); //added on 10/12 test
        if (e.target.value >= 0) {
            setDonation(parseInt(e.target.value));
            setTotalAmount((parseInt(inputFields.quantities) * inputFields.itemPrice + parseInt(e.target.value)).toFixed(2));
        }
        if (feeEnabled) {
            setTotalAmount(((parseInt(inputFields.quantities) * inputFields.itemPrice + parseInt(e.target.value)) * 1.03).toFixed(2));
        }
    };

    const initialOptions = {
        clientId: process.env.REACT_APP_PROD_CLIENT_ID,
        currency: "USD",
        intent: "capture",
        "disable-funding": "paylater",
    };

    const [success, setSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [orderId, setOrderId] = useState(false)
    const [checkout, setCheckout] = useState(false)


    const createOrder = (data, actions) => {
        var itemArray = [];

        if (eventData.transaction.quantities > 0) {
            itemArray[itemArray.length] = {
                name: "High School Retreat Amount",
                description: "High School Retreat Amount",
                unit_amount: {
                    currency_code: "USD",
                    value: "" + parseInt(inputFields.itemPrice * inputFields.quantities),
                },
                quantity: "1",
            }
        }
        console.log('items array donation:', eventData.transaction.donationAmount);
        if (eventData.transaction.donationAmount > 0) {
            itemArray[itemArray.length] = {
                name: "Donation Amount",
                description: "Donation item",
                unit_amount: {
                    currency_code: "USD",
                    value: eventData.transaction.donationAmount,
                },
                quantity: "1",
            }
        }
        console.log('items array fee:', gFeeEnabled);
        if (gFeeEnabled > 0) {
            itemArray[itemArray.length] = {
                name: "Credit Card processing Fee Amount",
                description: "Credit Card Fee item",
                unit_amount: {
                    currency_code: "USD",
                    value: ((parseInt(inputFields.quantities) * inputFields.itemPrice + parseInt(eventData.transaction.donationAmount)) * 0.03).toFixed(2),
                },
                quantity: "1",
            }
        }
        console.log('items array in create order 2:', itemArray, eventData.transaction.totalAmount, eventData.transaction.donationAmount);
        return actions.order.create({
            purchase_units: [
                {
                    description: "High School Retreat Event",
                    amount: {
                        value: eventData.transaction.totalAmount,
                        currency_code: "USD",
                        breakdown: {
                            item_total: {
                                value: eventData.transaction.totalAmount,
                                currency_code: "USD",
                            }
                        }
                    },

                    items: itemArray, /**/
                },
            ],
            application_context: {
                shipping_preference: "NO_SHIPPING"
            }
        })
            .then((orderId) => {
                setOrderId(orderId)
                return orderId
            })
    }
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details
            setSuccess(true);
            setCheckout(true);
            eventData.transaction.paymentStatus = 'paid';
            eventData.transaction.payerId = payer.payer_id;
            console.log('Payer info : ', payer);
            //eventData.transaction = inputFields;
            console.log('on Approve Event data : ', eventData);
            sendToServer();
        })
    }
    const onError = (data, actions) => {
        setErrorMessage("An error occured with your payment")
    }

    return (
        <div>
            <form onSubmit={checkoutHandler}>
                <Grid container direction="column" alignItems="center" >
                    {
                        checkout ?
                            success ?
                                <Grid item>
                                    <img src={successIcon} className="Success-icon" alt="logo" />
                                    <Typography variant="h5" align="center" gutterBottom>
                                        {'Thank You for the Registration'}
                                    </Typography>
                                    <Box sx={{ width: '100%', maxWidth: 500 }}>
                                        <Typography variant="body2" align="left" gutterBottom>
                                            You will receive an email confirmation to this address <b> [ {eventData.parent.email} ] </b>
                                        </Typography>
                                    </Box>
                                </Grid>
                                :
                                <Grid item>
                                    <Grid item>
                                        <div align="left"><b>Payment Information</b></div>
                                    </Grid>
                                    <Grid item>
                                        <Divider variant="middle" />
                                    </Grid>
                                    <div><br /></div>
                                    <Grid container direction="row" spacing={2} alignItems="left" v>
                                        <Grid item>
                                            <TextField
                                                id="Quantity"
                                                disabled
                                                label="Quantities #"
                                                type="number"
                                                name="Quantities"
                                                variant="standard"
                                                value={inputFields.quantities}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="donation"
                                                label="Additional Donation ($)"
                                                type="number"
                                                name="donation"
                                                variant="standard"
                                                value={donation}
                                                onChange={handleDonation}
                                            />
                                        </Grid>

                                    </Grid>
                                    <Grid container direction="row" spacing={2} alignItems="center" v>
                                        <Grid item>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox checked={feeEnabled} />} label="Add additional 3% to cover for CC fees"
                                                    value={feeEnabled} size="small" onChange={feeSwitchHandler} />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                disabled
                                                id="totalAmount"
                                                label="Total Amount($)"
                                                type="number"
                                                name="totalAmount"
                                                variant="standard"
                                                inputProps={{ style: { fontSize: 20 } }} // font size of input text
                                                InputLabelProps={{ style: { fontSize: 20 } }} // font size of input label
                                                value={totalAmount}
                                            />
                                        </Grid>
                                    </Grid>


                                    <span>
                                        <Box sx={{ width: '100%', maxWidth: 500 }}>
                                            <Typography variant="h6" align="left" gutterBottom>
                                                <b>If you want make any changes, please click "Back" button otherwise click "Paypal"
                                          button to complete the transactions</b>
                                                <div><br /></div>
                                            </Typography>
                                        </Box>
                                    </span>
                                    <PayPalScriptProvider options={initialOptions}>
                                        <PayPalButtons style={{ layout: "vertical", label: "pay" }}
                                            createOrder={createOrder}
                                            onApprove={onApprove}

                                        />
                                    </PayPalScriptProvider>
                                    <span>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => handleBack()}>Back</Button>
                                    </span>
                                </Grid>
                            :
                            <div>
                                <Grid item xs={12} sm={12}>
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }} size="small">
                                        <InputLabel id="demo-simple-select-standard-label">Center</InputLabel>
                                        <Select
                                            id="standard"
                                            required
                                            name="center"
                                            value={eventData.center}
                                            onChange={handleCampChange}
                                            label="Center"
                                        >
                                            <MenuItem value="Chitrakoot">Chitrakoot</MenuItem>
                                            <MenuItem value="Saaket">Saaket</MenuItem>
                                            <MenuItem value="Frisco">Frisco</MenuItem>
                                            <MenuItem value="Other">Other</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <div><br /></div>
                                </Grid>
                                <StudentInfoComp eventData={eventData} setEventData={setEventData} />
                                <ParentInfoComp eventData={eventData} />
                                <SpouseInfoComp eventData={eventData} setEventData={setEventData} />
                                <div><br /></div>
                                <Grid item xs={12} sm={12}>
                                    <Box sx={{ width: '100%' }}>
                                        <Typography variant="body2" align="left" gutterBottom>
                                            * We will contact you primarily through email with camp information, and in case of emergencies
                                by phone. Please ensure that you regularly check or respond to the email account/ phone
                                number you have submitted above.
                                </Typography>
                                    </Box>
                                </Grid>
                                <MedicalInfoComp eventData={eventData} setEventData={setEventData} />
                                <div><br /></div>
                                <div><br /></div>


                                <Grid container direction="column" spacing={2} alignItems="center" >
                                    <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Checkout</Button>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center" gutterBottom>
                                        If you have any questions, please contact us at <a href="mailto:events@cmdfw.org">events@cmdfw.org</a>
                                    </Typography>
                                </Grid>
                            </div>
                    }
                </Grid>
            </form>
        </div >
    );
}