import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Switch from "@mui/material/Switch";
import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from "@mui/material/Box";
import successIcon from '../assets/payment-success-icon.png';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';


var gFeeEnabled = {};
export default function SolarEclipseForm() {
    const [eventData, setEventData] = useState({
        parent: {
            memberType: "parent",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: "",
            emergencyContactName: "",
            emergencyContactMobile: ""
        },
        spouse: {},
        children: [],
        guest: [],
        transaction: {
            quantities: 1,
            itemPrice: 0,
            totalAmount: "",
            donationAmount: "",
            paymentStatus: "",
            payerId: ""
        },
        medical: {
            pcpName: "",
            pcpMobile: "",
            medicalInsurance: "",
            groupNo: "",
            idNo: "",
            medicalNotes: "",
            medicalConsentSignedBy: "",
            treatmentConsentSignedBy: ""
        },
        tshirts: [],
        eventType: 'SolarEclipse',
        center: '',
        notes: '',
        otherCenter: ''
    });

    const [inputFields, setInputFields] = useState(eventData.transaction);
    const [parentFields, setParentFields] = useState(eventData.parent);
    const [guestFields, setGuestFields] = useState(eventData.guest);
    const [childFields, setChildFields] = useState(eventData.children);
    const [medicalFields, setMedicalFields] = useState(eventData.medical);

    const [addGuest, setAddGuest] = useState(false);
    const [addChild, setAddChild] = useState(false);
    const [noOfPeople, setNoOfPeople] = useState(1);
    const [noOfGuests, setNoOfGuests] = useState(0);
    const [noOfChildren, setNoOfChildren] = useState(0);

    const ticketPrice = process.env.REACT_APP_SOLARECLIPSE_PRICE;

    const [donation, setDonation] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [feeEnabled, setFeeEnabled] = useState(0);
    const [addVolunteer, setAddVolunteer] = useState();
    const [otherCenterFlag, setOtherCenterFlag] = useState(false);

    const total = () => {
        inputFields.donationAmount = donation;
        inputFields.totalAmount = totalAmount;
        eventData.transaction = inputFields;
        eventData.parent = parentFields;
        gFeeEnabled = feeEnabled;
        if (eventData.center !== 'Other') {
            eventData.otherCenter = "";
        }
        if (!addGuest) {
            eventData.guest = [];
        }
        if (!addChild) {
            eventData.children = [];
            //setNoOfChildren(0);
        }
    }
    total();

    //    console.log("Solar Eclipse form data 2:", eventData, process.env);

    // useEffect(() => {
    //        setAddGuest(false);
    //    }, [noOfPeople <= 1])

    useEffect(() => {
        eventData.transaction.quantities = noOfPeople;
        eventData.transaction.itemPrice = ticketPrice;
        if (feeEnabled) {
            setTotalAmount(((parseInt(noOfPeople) * parseInt(ticketPrice) + parseInt(donation)) * 1.03).toFixed(2));
        } else {
            setTotalAmount((parseInt(noOfPeople) * parseInt(ticketPrice) + parseInt(donation)) * 1.00);
        }

        eventData.transaction.totalAmount = totalAmount;
    }, [noOfPeople, donation, feeEnabled])

    function handleCenterChange(e) {
        setEventData({ ...eventData, [e.target.name]: e.target.value });
        if (e.target.value === 'Other') {
            setOtherCenterFlag(true);
        } else {
            setOtherCenterFlag(false);
        }
    }

    /*    function handlePeople(e) {
            setNoOfPeople(parseInt(e.target.value));
        }; */

    const handleAddFields = () => {
        setGuestFields([...guestFields, { memberType: "Guest", firstName: "", lastName: "", mobileNumber: "" }]);
        setNoOfPeople(noOfPeople + 1);
        setNoOfGuests(noOfGuests + 1);
    };

    const handleRemoveFields = (index) => {
        const values = [...guestFields];
        values.splice(index, 1);
        setGuestFields(values);
        eventData.guest = values;
        setNoOfPeople(noOfPeople - 1);
        setNoOfGuests(noOfGuests - 1);
    };

    function volunteerSwitchHandler(e) {
        setAddVolunteer(e.target.checked);
        eventData.parent.volunteer = e.target.checked ? "true" : "false";
    };

    function handleParentChange(e) {
        let newForm = { ...parentFields };
        newForm[e.target.name] = e.target.value;
        setParentFields(newForm);
        eventData.parent = newForm;
    }

    const handleAddChildFields = () => {
        setChildFields([...childFields, { memberType: "child", firstName: "", lastName: "", grade: "", gender: "" }]);
        setNoOfPeople(noOfPeople + 1);
        setNoOfChildren(noOfChildren + 1);


    };

    const handleRemoveChildFields = (index) => {
        const values = [...childFields];
        values.splice(index, 1);
        setChildFields(values);
        eventData.children = values;
        setNoOfPeople(noOfPeople - 1);
        setNoOfChildren(noOfChildren - 1);
    };

    const handleChildChange = (e, index) => {
        let newForm = [...childFields];
        newForm[index][e.target.name] = e.target.value;
        setChildFields(newForm);
        eventData.children = newForm;
    }

    const handleGuestChange = (e, index) => {
        let newForm = [...guestFields];
        newForm[index][e.target.name] = e.target.value;
        setGuestFields(newForm);
        eventData.guest = newForm;
    }

    function handleMedicalChange(e) {
        let newForm = { ...medicalFields };
        newForm[e.target.name] = e.target.value;
        setMedicalFields(newForm);
        eventData.medical = newForm;
    }

    function childSwitchHandler(e) {
        setAddChild(e.target.checked);
        setChildFields([{ memberType: "child", firstName: "", lastName: "", mobileNumber: "" }]);
        if (e.target.checked) {
            setNoOfPeople(noOfPeople + 1);
            setNoOfChildren(noOfChildren + 1);
        } else {
            setNoOfPeople(1 + noOfGuests);
            setNoOfChildren(0);
        };
    }

    function guestSwitchHandler(e) {
        setAddGuest(e.target.checked);
        setGuestFields([{ memberType: "Guest", firstName: "", lastName: "", mobileNumber: "" }]);
        if (e.target.checked) {
            setNoOfPeople(noOfPeople + 1);
            setNoOfGuests(noOfGuests + 1);
        } else {
            setNoOfPeople(1 + noOfChildren);
            setNoOfGuests(0);
        };
    }

    const checkoutHandler = (e) => {
        e.preventDefault();
        if (sendToServer()) {
            setCheckout(true);
        } else {
            setCheckout(false);
        }
    };

    function sendToServer() {

        console.log("transaction before sent to SQS:", (eventData));

        try {

            const url = process.env.REACT_APP_SQS_URL;

            const response = fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(eventData),
            });
            console.log('API response:', response.data);
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
        return true;
    }

    function feeSwitchHandler(e) {
        //Calculate total amount based on Credit card fee enabled check box. Add extra 3% to the total amount
        setFeeEnabled(e.target.checked);
    }

    function handleBack() {
        // User clicks on GO BACK button 
        //        console.log('Go back response:', eventData);
        setCheckout(false);
    }


    function handleDonation(e) {
        setInputFields({ ...inputFields, [e.target.name]: parseInt(e.target.value) });
        if (e.target.value >= 0) {
            setDonation(parseInt(e.target.value));
        }
        //        console.log('Donation:', e.target.value, eventData, eventData.transaction.totalAmount)
    };

    const initialOptions = {
        clientId: process.env.REACT_APP_PROD_CLIENT_ID,
        currency: "USD",
        intent: "capture",
        "disable-funding": "paylater",
    };

    const [success, setSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [orderId, setOrderId] = useState(false)
    const [checkout, setCheckout] = useState(false)


    const createOrder = (data, actions) => {
        var itemArray = [];

        if (eventData.transaction.quantities > 0) {
            itemArray[itemArray.length] = {
                name: "2024 CMW Solar Eclipse Retreat Amount ",
                description: "2024 CMW Solar Eclipse Retreat Amount ",
                unit_amount: {
                    currency_code: "USD",
                    value: "" + (parseInt(noOfPeople) * parseInt(ticketPrice)),
                },
                quantity: "1",
            }
        }
        if (eventData.transaction.donationAmount > 0) {
            itemArray[itemArray.length] = {
                name: "Donation Amount",
                description: "Donation item",
                unit_amount: {
                    currency_code: "USD",
                    value: eventData.transaction.donationAmount,
                },
                quantity: "1",
            }
        }
        if (gFeeEnabled > 0) {
            itemArray[itemArray.length] = {
                name: "Credit Card processing Fee Amount",
                description: "Credit Card Fee item",
                unit_amount: {
                    currency_code: "USD",
                    value: ((parseInt(noOfPeople) * parseInt(ticketPrice) + parseInt(eventData.transaction.donationAmount)) * 0.03).toFixed(2),
                },
                quantity: "1",
            }
        }

        console.log('items array in create order 21:', itemArray, eventData.transaction.totalAmount);
        return actions.order.create({
            purchase_units: [
                {
                    description: "2024 CMW Solar Eclipse Retreat Event",
                    amount: {
                        value: eventData.transaction.totalAmount,
                        currency_code: "USD",
                        breakdown: {
                            item_total: {
                                value: eventData.transaction.totalAmount,
                                currency_code: "USD",
                            }
                        }
                    },

                    items: itemArray, /**/
                },
            ],
            application_context: {
                shipping_preference: "NO_SHIPPING"
            }
        })
            .then((orderId) => {
                setOrderId(orderId)
                return orderId
            })
    }
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details
            setSuccess(true);
            setCheckout(true);
            eventData.transaction.paymentStatus = 'paid';
            eventData.transaction.payerId = payer.payer_id;
            console.log('on Approve Event data : ', eventData);
            sendToServer();
        })
    }
    const onError = (data, actions) => {
        setErrorMessage("An error occured with your payment")
    }
    return (
        <div>
            <form onSubmit={checkoutHandler}>
                <Grid container direction="column" spacing={2} alignItems="center" >
                    {
                        checkout ?
                            success ?
                                <Grid item>
                                    <img src={successIcon} className="Success-icon" alt="logo" />
                                    <Typography variant="h5" align="center" gutterBottom>
                                        {'Thank You for the Registration'}
                                    </Typography>
                                    <Box sx={{ width: '100%', maxWidth: 500 }}>
                                        <Typography variant="body2" align="left" gutterBottom>
                                            You will receive an email confirmation to this address <b> [ {eventData.parent.email} ] </b>
                                        </Typography>
                                    </Box>
                                </Grid>
                                :
                                <Grid item>
                                    <span>
                                        <Box sx={{ width: '100%', maxWidth: 500 }}>
                                            <Typography variant="h6" align="left" gutterBottom>
                                                <b>If you want to make any changes, please click "Back" button otherwise click "Paypal"
                                          button to complete the transactions</b>
                                                <div><br /></div>
                                            </Typography>
                                        </Box>
                                    </span>
                                    <PayPalScriptProvider options={initialOptions}>
                                        <PayPalButtons style={{ layout: "vertical", label: "pay" }}
                                            createOrder={createOrder}
                                            onApprove={onApprove}
                                        />
                                    </PayPalScriptProvider>
                                    <span>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => handleBack()}>Back</Button>
                                    </span>
                                </Grid>
                            :
                            <Grid item xs={12} sm={12}>
                                <Grid item>
                                    <Box sx={{ width: '100%', minWidth: 300 }}>
                                        <Typography variant="body2" align="left" gutterBottom>
                                            <b>Where:</b>Chinmaya Mangalam | 10470 FM744, Barry TX, 75102
                                            <div><b>When: </b>Saturday April 6th 6PM - Monday April 8th 4PM</div>
                                            <b>COST: </b>$200/ per Person <b>(6th graders and above) Limited capcity</b>
                                            <div><br /></div>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }} size="small">
                                        <InputLabel id="demo-simple-select-standard-label">Select a Center</InputLabel>
                                        <Select
                                            id="standard"
                                            required
                                            name="center"
                                            label="Center"
                                            value={eventData.center}
                                            onChange={handleCenterChange}
                                        >
                                            <MenuItem value="Chitrakoot">Chitrakoot</MenuItem>
                                            <MenuItem value="Frisco">Frisco</MenuItem>
                                            <MenuItem value="Saaket">Saaket</MenuItem>
                                            <MenuItem value="Other">Other</MenuItem>

                                        </Select>
                                        {otherCenterFlag && (
                                            <TextField
                                                fullWidth
                                                id="otherCenter"
                                                label="Enter Center :"
                                                sx={{ m: 1, minWidth: 150 }}
                                                variant="standard"
                                                name="otherCenter"
                                                value={eventData.otherCenter}
                                                onChange={e => setEventData({ ...eventData, [e.target.name]: e.target.value })}
                                            />)}
                                    </FormControl>
                                    <div><br /></div>
                                </Grid>
                                <Grid container align="left" spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="fName"
                                            label="First Name"
                                            name="firstName"
                                            variant="standard"
                                            fullWidth
                                            value={parentFields.firstName}
                                            onChange={handleParentChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="lName"
                                            label="Last Name"
                                            name="lastName"
                                            variant="standard"
                                            fullWidth
                                            value={parentFields.lastName}
                                            onChange={handleParentChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="mobile"
                                            label="Mobile Phone"
                                            name="mobileNumber"
                                            variant="standard"
                                            fullWidth
                                            value={parentFields.mobileNumber}
                                            onChange={handleParentChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
                                            <InputLabel id="demo-simple-select-standard-label">Gender</InputLabel>
                                            <Select
                                                required
                                                id="standard"
                                                name="gender"
                                                value={parentFields.gender}
                                                onChange={handleParentChange}
                                                label="Gender"
                                            >
                                                <MenuItem value="Male">Male</MenuItem>
                                                <MenuItem value="Female">Female</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            type="email"
                                            fullWidth
                                            required
                                            id="email"
                                            label="Email"
                                            variant="standard"
                                            name="email"
                                            value={parentFields.email}
                                            onChange={handleParentChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="Notes"
                                            fullWidth
                                            required
                                            multiline
                                            label="Notes (Special needs, Food allergies):"
                                            name="notes"
                                            variant="standard"
                                            value={parentFields.notes}
                                            onChange={handleParentChange}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={addVolunteer} />} label="Are you interested in Volunteering"
                                            value={addVolunteer} size="small" onChange={volunteerSwitchHandler} />
                                    </FormGroup>
                                </Grid>
                                <div><br /></div>

                                <Grid container >

                                    <Grid item>
                                        <Divider variant="middle" />
                                    </Grid>

                                    <Grid item xs={12}>

                                        {noOfPeople >= 1 &&
                                            <FormGroup>
                                                <FormControlLabel control={<Switch checked={addGuest} />} label="Add Spouse/Guest"
                                                    size="small" onChange={guestSwitchHandler} />
                                            </FormGroup>
                                        }

                                        {addGuest && noOfPeople >= 1 && guestFields.map((guestFields, index) => (
                                            <div key={index} align="left">
                                                <Grid container spacing={2} alignItems="left">
                                                    <Grid item xs={6} sm={6}>
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            id="firstName"
                                                            name="firstName"
                                                            label="First Name"
                                                            variant="standard"
                                                            value={guestFields.firstName}
                                                            onChange={(e) => handleGuestChange(e, index)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={6}>
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            id="lastName"
                                                            name="lastName"
                                                            label="Last Name"
                                                            variant="standard"
                                                            value={guestFields.lastName}
                                                            onChange={(e) => handleGuestChange(e, index)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            id="mobileNumber"
                                                            type="number"
                                                            name="mobileNumber"
                                                            label="Mobile Phone"
                                                            variant="standard"
                                                            value={guestFields.mobileNumber}
                                                            onChange={(e) => handleGuestChange(e, index)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
                                                            <InputLabel id="demo-simple-select-standard-label">Gender</InputLabel>
                                                            <Select
                                                                required
                                                                id="standard"
                                                                name="gender"
                                                                value={guestFields.gender}
                                                                onChange={(e) => handleGuestChange(e, index)}
                                                                label="Gender"
                                                            >
                                                                <MenuItem value="Male">Male</MenuItem>
                                                                <MenuItem value="Female">Female</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            id="Notes"
                                                            fullWidth
                                                            required
                                                            multiline
                                                            label="Notes (Special needs, Food allergies):"
                                                            name="notes"
                                                            variant="standard"
                                                            value={guestFields.notes}
                                                            onChange={(e) => handleGuestChange(e, index)}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={6}>
                                                        <IconButton onClick={() => handleRemoveFields(index)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleAddFields(index)}>
                                                            <PersonAddIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        ))}
                                    </Grid>
                                    <Grid item xs={12}>

                                        {noOfPeople >= 1 &&
                                            <FormGroup>
                                                <FormControlLabel control={<Switch checked={addChild} />} label="Add Children"
                                                    size="small" onChange={childSwitchHandler} />
                                            </FormGroup>
                                        }

                                        {addChild && noOfPeople >= 1 && childFields.map((childFields, index) => (
                                            <div key={index} align="left">
                                                <Grid container spacing={2} alignItems="left">
                                                    <Grid item xs={6} sm={6}>
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            id="firstName"
                                                            name="firstName"
                                                            label="First Name"
                                                            variant="standard"
                                                            value={childFields.firstName}
                                                            onChange={(e) => handleChildChange(e, index)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={6}>
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            id="lastName"
                                                            name="lastName"
                                                            label="Last Name"
                                                            variant="standard"
                                                            value={childFields.lastName}
                                                            onChange={(e) => handleChildChange(e, index)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={6}>
                                                        <TextField
                                                            required
                                                            id="grade"
                                                            label="Grade"
                                                            type="number"
                                                            style={{ width: '150px' }}
                                                            name="grade"
                                                            inputProps={{ min: 6, max: 12 }}
                                                            variant="standard"
                                                            value={childFields.grade}
                                                            onChange={(e) => handleChildChange(e, index)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
                                                            <InputLabel id="demo-simple-select-standard-label">Gender</InputLabel>
                                                            <Select
                                                                required
                                                                id="standard"
                                                                name="gender"
                                                                value={childFields.gender}
                                                                onChange={(e) => handleChildChange(e, index)}
                                                                label="Gender"
                                                            >
                                                                <MenuItem value="Male">Male</MenuItem>
                                                                <MenuItem value="Female">Female</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            id="Notes"
                                                            fullWidth
                                                            required
                                                            multiline
                                                            label="Notes (Special needs, Food allergies):"
                                                            name="notes"
                                                            variant="standard"
                                                            value={childFields.notes}
                                                            onChange={(e) => handleChildChange(e, index)}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sm={6}>
                                                        <IconButton onClick={() => handleRemoveChildFields(index)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleAddChildFields(index)}>
                                                            <PersonAddIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        ))}
                                    </Grid>
                                </Grid>
                                <div><br /></div>

                                <Typography variant="h6" align="left" gutterBottom>
                                    <b>Emergency Contact </b>
                                    <b>(Not attending the Retreat)</b>
                                </Typography>

                                <Grid container align="left" spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="contactName"
                                            label="Contact Name"
                                            name="emergencyContactName"
                                            variant="standard"
                                            fullWidth
                                            value={parentFields.emergencyContactName}
                                            onChange={e => setParentFields({ ...parentFields, [e.target.name]: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="contactMobile"
                                            type="number"
                                            label="Contact Mobile Phone"
                                            name="emergencyContactMobile"
                                            variant="standard"
                                            fullWidth
                                            value={parentFields.emergencyContactmobile}
                                            onChange={e => setParentFields({ ...parentFields, [e.target.name]: e.target.value })}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="column" align="left">
                                    <div><br /></div>
                                    <Typography variant="h6" align="left" gutterBottom>
                                        <b>Medical Information (Optional)</b>
                                    </Typography>

                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                id="pcpName"
                                                label="Primay Care Physican"
                                                name="pcpName"
                                                variant="standard"
                                                value={medicalFields.pcpName}
                                                onChange={handleMedicalChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                id="pcpmobile"
                                                label="Physican Phone #"
                                                name="pcpMobile"
                                                variant="standard"
                                                value={medicalFields.pcpMobile}
                                                onChange={handleMedicalChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                fullWidth
                                                id="medicalInsurance"
                                                label="Medical Insurance"
                                                name="medicalInsurance"
                                                variant="standard"
                                                value={medicalFields.medicalInsurance}
                                                onChange={handleMedicalChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                id="groupNo"
                                                label="Group No"
                                                name="groupNo"
                                                variant="standard"
                                                value={medicalFields.groupNo}
                                                onChange={handleMedicalChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                id="idNo"
                                                label="ID No (if any)"
                                                name="idNo"
                                                variant="standard"
                                                value={medicalFields.idNo}
                                                onChange={handleMedicalChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h8" align="left" gutterBottom>
                                                <b>Release and Medical Authorization</b>
                                            </Typography>
                                            <div><br /></div>
                                            RELEASE OF LIABILITY - In consideration of Chinmaya Mission granting the above named
                                retreater permission to participate in the labor day retreat activities including being transported to
                                and back from the retreat location, I hereby assume all risks of her/his personal injury (including
                                death) that may result from any retreat activity (including residence hall activities). As
                                parent/guardian/responsible party, I do indemnify, defend and hold harmless, Chinmaya Mission
                                and its officers, Volunteer parents and all participants in the retreat program from and against all
                                liability, including claims and suits at law or in equity, for injury, fatal or otherwise, which may
                                result from any negligence and/or the retreater taking part in activities.
                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                id="medicalConsentSignedBy"
                                                required
                                                fullWidth
                                                label="Signature  (Type your Name) :"
                                                name="medicalConsentSignedBy"
                                                variant="standard"
                                                value={medicalFields.medicalConsentSignedBy}
                                                onChange={handleMedicalChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h8" align="left" gutterBottom>
                                                <b>CONSENT FOR TREATMENT AND/OR FIRST AID</b>
                                            </Typography>
                                            <div><br /></div>
                                            <p>
                                                In the event of injury or illness, I hereby
                                give my consent for medical treatment and permission to the assigned retreat doctor or any
                                adult retreat volunteer or any Hospital to supervise on-site first aid for minor injuries, and to a
                                licensed physician to hospitalize and secure proper treatment (including injections, anesthesia,
                                surgery, or other reasonable treatment and necessary procedures) for the retreater.
                                </p>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                id="treatmentConsentSignedBy"
                                                required
                                                fullWidth
                                                label="Signature (Type your Name) :"
                                                name="treatmentConsentSignedBy"
                                                variant="standard"
                                                value={medicalFields.treatmentConsentSignedBy}
                                                onChange={handleMedicalChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <div><br /></div>
                                <Typography variant="h6" align="left" gutterBottom>
                                    <b>Payment Information</b>
                                </Typography>
                                <div><br /></div>
                                <Grid container direction="row" spacing={2} >
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="Quantity"
                                            fullWidth
                                            disabled
                                            label="Quantities #"
                                            type="number"
                                            name="Quantities"
                                            variant="standard"
                                            value={inputFields.quantities}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            number
                                            id="donation"
                                            label="Additional Donation ($)"
                                            type="number"
                                            name="donation"
                                            variant="standard"
                                            value={donation}
                                            onChange={handleDonation}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={feeEnabled} />} label="Add 3% to cover for CC fees"
                                                value={feeEnabled} size="small" onChange={feeSwitchHandler} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            disabled
                                            id="totalAmountField"
                                            label="Total Amount($)"
                                            type="number"
                                            name="totalAmount"
                                            variant="standard"
                                            inputProps={{ style: { fontSize: 20 } }} // font size of input text
                                            InputLabelProps={{ style: { fontSize: 20 } }} // font size of input label
                                            value={totalAmount}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container direction="column" spacing={5}>
                                    <Grid item>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Checkout</Button>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" align="center" gutterBottom>
                                            If you have any questions, please contact us at <a href="mailto:events@cmdfw.org">events@cmdfw.org</a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                    }
                </Grid>
            </form>


        </div>
    );
}