import React from 'react';
import EventsForm from './EventsForm';
import BanquetForm from './BanquetForm';


function App() {
  return (
    <div className="App">
      <EventsForm />


    </div>
  );
}

export default App;
