import React, { useState, useEffect } from 'react';
import logo from '../assets/banquet-banner.png';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';


export default function MedicalInfoComp({ eventData, setEventData }) {
    console.log("Event data in Medical form 1 :", JSON.stringify(eventData));
    const [inputFields, setInputFields] = useState(eventData.medical);

    function handleChange(e) {
        let newForm = { ...inputFields };
        newForm[e.target.name] = e.target.value;
        setInputFields(newForm);
        eventData.medical = newForm;
    }

    return (
        <div>
            <React.Fragment>
                <Grid container direction="column" align="left">
                    <div><br /></div>
                    <Typography variant="h6" align="left" gutterBottom>
                        <b>Medical Information</b>
                    </Typography>

                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="pcpName"
                                label="Primay Care Physican"
                                name="pcpName"
                                variant="standard"
                                value={inputFields.pcpName}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                required
                                id="pcpmobile"
                                label="Physican Phone #"
                                name="pcpMobile"
                                variant="standard"
                                value={inputFields.pcpMobile}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                required
                                id="medicalInsurance"
                                label="Medical Insurance"
                                name="medicalInsurance"
                                variant="standard"
                                value={inputFields.medicalInsurance}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                required
                                id="groupNo"
                                label="Group No"
                                name="groupNo"
                                variant="standard"
                                value={inputFields.groupNo}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="idNo"
                                label="ID No (if any)"
                                name="idNo"
                                variant="standard"
                                value={inputFields.idNo}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h8" align="left" gutterBottom>
                                <b>Release and Medical Authorization</b>
                            </Typography>
                            <div><br /></div>
                            RELEASE OF LIABILITY - In consideration of Chinmaya Mission granting the above named
                                camper permission to participate in the labor day retreat activities including being transported to
                                and back from the retreat location, I hereby assume all risks of her/his personal injury (including
                                death) that may result from any camp activity (including residence hall activities). As
                                parent/guardian/responsible party, I do indemnify, defend and hold harmless, Chinmaya Mission
                                and its officers, Volunteer parents and all participants in the camp program from and against all
                                liability, including claims and suits at law or in equity, for injury, fatal or otherwise, which may
                                result from any negligence and/or the camper taking part in activities.
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                id="medicalConsentSignedBy"
                                required
                                fullWidth
                                label="Signature  (Type your Name) :"
                                name="medicalConsentSignedBy"
                                variant="standard"
                                value={inputFields.medicalConsentSignedBy}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h8" align="left" gutterBottom>
                                <b>CONSENT FOR TREATMENT AND/OR FIRST AID</b>
                            </Typography>
                            <div><br /></div>
                            <p>
                                In the event of injury or illness, I hereby
                                give my consent for medical treatment and permission to the assigned retreat doctor or any
                                adult retreat volunteer or any Hospital to supervise on-site first aid for minor injuries, and to a
                                licensed physician to hospitalize and secure proper treatment (including injections, anesthesia,
                                surgery, or other reasonable treatment and necessary procedures) for the camper.
                                <p><b>EVERY ATTEMPT WILL BE MADE TO CONTACT YOU, THE PARENT/GUARDIAN/RESPONSIBLE
                                PARTY, PRIOR TO ANY MEDICAL ATTENTION BEYOND MINOR FIRST AID, IS GIVEN.</b></p></p>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                id="treatmentConsentSignedBy"
                                required
                                fullWidth
                                label="Signature (Type your Name) :"
                                name="treatmentConsentSignedBy"
                                variant="standard"
                                value={inputFields.treatmentConsentSignedBy}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                id="medicalNotes"
                                fullWidth
                                required
                                multiline
                                label="Notes (Special needs, Food allergies):"
                                name="medicalNotes"
                                variant="standard"
                                value={inputFields.medicalNotes}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        </div>
    );
}
