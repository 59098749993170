import React, { useState, useEffect } from 'react';
import logo from '../assets/banquet-banner.png';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';


export default function StudentInfoComp({ eventData, setEventData }) {
    //console.log("Event data in Student form :", JSON.stringify(eventData));
    const [inputFields, setInputFields] = useState(eventData.children);
    const [transactionFields, setTransactionFields] = useState(eventData.transaction);
    const [numberOfStudents, setNumberOfStudents] = useState(eventData.transaction.quantities);
    //console.log("Event data in Student input fields form :", (eventData));


    const handleChange = (e, index) => {
        //    console.log("Event data in Student handle form :", (inputFields));        
        let newForm = [...inputFields];
        newForm[index][e.target.name] = e.target.value;
        setInputFields(newForm);
        eventData.children = newForm;

        transactionFields.quantities = numberOfStudents;
        transactionFields.totalAmount = (numberOfStudents * transactionFields.itemPrice);
        eventData.transaction.quantities = numberOfStudents;
        eventData.transaction.totalAmount = (numberOfStudents * transactionFields.itemPrice);

        //console.log("Event data in handle change form 1 :", (eventData));
    }
    const handleAddFields = () => {
        setInputFields([...inputFields, {
            memberType: "child", firstName: "", lastName: "", mobileNumber: "",
            gender: "", age: 0, grade: "", streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        }]);
        setNumberOfStudents(numberOfStudents + 1);
        eventData.transaction.quantities = numberOfStudents;
        console.log("Event data after add fields :", (eventData));
    };

    const handleRemoveFields = (index) => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
        eventData.children = values;

        console.log("Number of students before remove :", (numberOfStudents));
        let students = numberOfStudents - 1
        setNumberOfStudents(numberOfStudents - 1);
        console.log("Number of students after remove :", (numberOfStudents), (students));
        //    eventData.transaction.quantities = numberOfStudents;
        transactionFields.quantities = students;
        transactionFields.totalAmount = (students * transactionFields.itemPrice);
        eventData.transaction.quantities = students;
        eventData.transaction.totalAmount = (students * transactionFields.itemPrice);
        console.log("Event data after remove fields :", (eventData));
    };


    return (
        <div>
            <React.Fragment>
                <Grid container alignItems="center" >
                    <Typography variant="h7" align="left" gutterBottom>
                        <b>Bala Vihar Student Information</b>
                    </Typography>
                    <Grid item xs={12} sm={12}>
                        <Grid container align="left" >
                            {inputFields.map((inputFields, index) => (
                                <div key={index} align="left">
                                    <Grid container spacing={2} >
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="fName"
                                                label="First Name"
                                                name="firstName"
                                                variant="standard"
                                                value={inputFields.firstName}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="lName"
                                                label="Last Name"
                                                name="lastName"
                                                variant="standard"
                                                value={inputFields.lastName}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <TextField
                                                required
                                                id="age"
                                                label="Age"
                                                inputProps={{ min: 5, max: 25 }}
                                                type="number"
                                                style={{ width: '100px' }}
                                                name="age"
                                                variant="standard"
                                                value={inputFields.age}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <TextField
                                                required
                                                id="grade"
                                                label="Grade"
                                                type="number"
                                                style={{ width: '150px' }}
                                                name="grade"
                                                inputProps={{ min: 1, max: 12 }}
                                                variant="standard"
                                                value={inputFields.grade}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
                                                <InputLabel id="demo-simple-select-standard-label">Gender</InputLabel>
                                                <Select
                                                    required
                                                    id="standard"
                                                    name="gender"

                                                    value={inputFields.gender}
                                                    onChange={(e) => handleChange(e, index)}
                                                    label="Gender"
                                                >
                                                    <MenuItem value="Male">Male</MenuItem>
                                                    <MenuItem value="Female">Female</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
                                                <InputLabel id="demo-simple-select-standard-label">T-Shirt Size</InputLabel>
                                                <Select
                                                    id="standard"
                                                    name="tshirtSize"
                                                    value={inputFields.tshirtSize}
                                                    onChange={(e) => handleChange(e, index)}
                                                    label="Tshirt Size"
                                                >
                                                    <MenuItem value="Adult-XL">Adult-XL</MenuItem>
                                                    <MenuItem value="Adult-L">Adult-L</MenuItem>
                                                    <MenuItem value="Adult-M">Adult-M</MenuItem>
                                                    <MenuItem value="Adult-S">Adult-S</MenuItem>
                                                    <MenuItem value="Youth-L">Youth-L</MenuItem>
                                                    <MenuItem value="Youth-M">Youth-M</MenuItem>
                                                    <MenuItem value="Youth-S">Youth-S</MenuItem>
                                                    <MenuItem value="Youth-XS">Youth-XS</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="mobile"
                                                label="Mobile Phone"
                                                name="mobileNumber"
                                                variant="standard"
                                                value={inputFields.mobileNumber}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                type="email"
                                                fullWidth
                                                required id="email" label="Email" variant="standard"
                                                name="email"
                                                value={inputFields.email}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField

                                                id="streetAddress"
                                                label="Street Address"
                                                fullWidth
                                                name="streetAddress"
                                                variant="standard"
                                                value={inputFields.streetAddress}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={4}>
                                            <TextField

                                                id="city"
                                                label="City"
                                                name="city"
                                                variant="standard"
                                                value={inputFields.city}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={4}>
                                            <TextField
                                                fullWidth
                                                id="state"
                                                label="State"
                                                name="state"
                                                inputProps={{ maxLength: 2 }}
                                                variant="standard"
                                                value={inputFields.state}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={4}>
                                            <TextField
                                                fullWidth
                                                id="postalCode"
                                                label="Postal Code"
                                                type="number"

                                                name="postalCode"
                                                variant="standard"
                                                value={inputFields.postalCode}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                id="allergies"
                                                fullWidth
                                                multiline
                                                label="Notes (Special needs, Food allergies):"
                                                name="allergies"
                                                variant="standard"
                                                value={inputFields.allergies}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <div><br /></div>
                                            <b>Add/Delete Additional students</b>
                                            {index >= 1 ?
                                                <IconButton onClick={() => handleRemoveFields(index)}>
                                                    <DeleteIcon />
                                                </IconButton> : ''
                                            }
                                            <IconButton onClick={() => handleAddFields(index)}>
                                                <PersonAddIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}

                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        </div>
    );
}
